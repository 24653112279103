import { useRoutes } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
  const token = localStorage.getItem("authorization");
  // const user = useAppSelector((state) => state.auth.user);

  // const commonRoutes = [{ path: '/', element: <Landing /> }];
  // const commonRoutes = [{ path: '/', element: <MinimalLayout /> }];

  const routes = token ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);
  return <>{element}</>;
};
