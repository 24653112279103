import React from "react";
import { Box } from "@mui/material";
import { ProjectDocuments } from "./components/ProjectDocuments";
import { ProjectLinks } from "./components/ProjectLinks";
import { ProjectHistory } from "./components/ProjectHistory";
import { ProjectChatStack } from "../PrChatStack/ProjectChatStack";
import {  IProjectDocument, IProjectLink } from "../../../model/projectData";

interface ProjectWorkingPanelProps {
  projectDocuments?: IProjectDocument[];
  projectLinks?: IProjectLink[];
}

export const ProjectWorkingPanel = ({projectDocuments, projectLinks} : ProjectWorkingPanelProps) => {
 
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: "10px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <ProjectDocuments projectDocuments={projectDocuments} />
        <ProjectLinks projectLinks={projectLinks} />
        <ProjectHistory />
      </Box>
      <Box sx={{ ml: "35px" }}>
        <ProjectChatStack />
      </Box>
    </Box>
  );
};
