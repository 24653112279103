import React from "react";
import { Box, Typography } from "@mui/material";
import {
  IProjectNewRolesBlock,
  IProjectRole,
} from "../../../../model/projectData";

interface SelectedSkillsItemsProps {
  blockState: IProjectNewRolesBlock;
  projectRoles?: IProjectRole[];
}

export const SelectedSkillsItems = ({
  blockState,
  projectRoles,
}: SelectedSkillsItemsProps) => {
  const role = projectRoles?.find(
    (role) => role.id === blockState.selectedRole
  );
  const skill = role?.skill.find(
    (oneSkill) => oneSkill.id === blockState.selectedSkill
  );
  const subSkills = blockState.selectedSubSkills;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        p: "5px",
        gap: "15px",
        width: "285px",
        pl: "40px"
      }}
    >
      {skill && subSkills.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "12px",
            backgroundColor: "#00A1E7",
            p: "5px",
          }}
        >
          {skill.name}
        </Box>
      ) : (
        <Typography
          sx={{
            fontFamily: "Zen Maru Gothic",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Скиллы не выбраны
        </Typography>
      )}
      {subSkills.map((subskill, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "12px",
            backgroundColor: "#00A1E7",
            p: "5px",
          }}
        >
          {subskill.subSkillEntity.name}
        </Box>
      ))}
    </Box>
  );
};
