import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetUserInfoQuery } from "../../../services/user";

const UserContext = createContext<{
  userId: number | null;
  isLoading: boolean;
}>({
  userId: null,
  isLoading: true,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<number | null>(null);
  const { data, isLoading } = useGetUserInfoQuery({});

  useEffect(() => {
    if (data && !isLoading) {
      setUserId(data.id);
    }
  }, [data, isLoading]);

  return (
    <UserContext.Provider value={{ userId, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserId = ()=> useContext(UserContext);
