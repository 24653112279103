import { IProjectNewRolesBlock } from "../../model/projectData";
import { deepEqual } from "./deepEqual";

export const handleBlockChange = (
  prevStates: IProjectNewRolesBlock[],
  index: number,
  newBlock: IProjectNewRolesBlock
): IProjectNewRolesBlock[] => {
  const updatedBlockStates = [...prevStates];
  if (!deepEqual(updatedBlockStates[index], newBlock)) {
    updatedBlockStates[index] = newBlock;
  }
  return updatedBlockStates;
};

export const handleBlockReset = (
  prevStates: IProjectNewRolesBlock[],
  index: number,
  initialNewRoleState: IProjectNewRolesBlock
): IProjectNewRolesBlock[] => {
  const updatedBlockStates = [...prevStates];
  updatedBlockStates[index] = { ...initialNewRoleState };
  return updatedBlockStates;
};

export const isBlockFilled = (block: IProjectNewRolesBlock) => {
  return (
    block.selectedRole !== undefined &&
    block.selectedSkill !== undefined &&
    block.selectedSubSkills.length > 0 &&
    block.totalInProject !== 0
  );
};

export const addNewBlockIfNeeded = (
  newRoleBlockStates: IProjectNewRolesBlock[],
  setNewRoleBlockStates: React.Dispatch<
    React.SetStateAction<IProjectNewRolesBlock[]>
  >,
  initialNewRoleState: IProjectNewRolesBlock
) => {
  if (
    newRoleBlockStates.every(isBlockFilled) &&
    newRoleBlockStates.length < 5
  ) {
    setNewRoleBlockStates([...newRoleBlockStates, { ...initialNewRoleState }]);
  }
};

export const validateProjectData = (
  projectName: string,
  projectDisc: string,
  projectWHCount: number,
  selectedRoles: (number | undefined)[]
): string[] => {
  const warnings = [];
  const duplicateRoles = selectedRoles.some(
    (role, index) => role !== undefined && selectedRoles.indexOf(role) !== index
  );
  if (!projectName) {
    warnings.push("Добавьте название проекта");
  }
  if (!projectDisc) {
    warnings.push("Добавьте описание проекта");
  }
  if (projectWHCount === 0) {
    warnings.push("Установите корректное количество часов в неделю");
  }
  if (duplicateRoles) {
    warnings.push("Вакансии не могут содержать больше 1 уникальной роли");
  }
  if (!selectedRoles.some((role) => role !== undefined)){
   warnings.push("Должна быть выбрана хотя бы одна роль")
  }
  return warnings;
};
