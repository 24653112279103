import { useMemo } from "react";
import { IProject } from "../../model/projectData";

const useFilteredAndSortedProjects = (
  projects: IProject[],
  search: string,
  sort: string,
  selectedFilters: string[],
  selectedVacancies: string[],
  shouldResetFilters: boolean
) => {
  const filteredAndSortedProjects = useMemo(() => {

      const filteredProjects = shouldResetFilters 
      ? projects 
      : filterProjects(projects, search, selectedFilters, selectedVacancies);

    const sortedProjects = sortProjects(filteredProjects, sort);

    return sortedProjects;
  }, [projects, search, sort, selectedFilters, selectedVacancies, shouldResetFilters]);

  return filteredAndSortedProjects;
};

const filterProjects = (
  projects: IProject[],
  search: string,
  selectedFilters: string[],
  selectedVacancies: string[]
) => {
  return projects.filter(project => {
    const isIncludedInSearch = project.title.toLowerCase().includes(search.toLowerCase()) ||
      project.description.toLowerCase().includes(search.toLowerCase());

    const isInSelectedFilters = selectedFilters.length === 0 ||
      selectedFilters.some(selectedFilter =>
        project.vacancies.some(vacancy => vacancy.role.name === selectedFilter)
      );

    const isSelectedVacanciesEmpty = selectedVacancies.length === 0 ||
      selectedVacancies.every(selectedVacancy =>
        project.vacancies.some(vacancy => vacancy.role.name === selectedVacancy)
      );

    return isIncludedInSearch && isInSelectedFilters && isSelectedVacanciesEmpty;
  });
};

const sortProjects = (projects: IProject[], sort: string) => {
  return [...projects].sort((a, b) => {
    switch (sort) {
      case "new":
        return b.startTimestamp - a.startTimestamp;
      case "old":
        return a.startTimestamp - b.startTimestamp;
      case "completed":
        return sortCompletedProjects(a, b);
      case "abc":
        return a.title.localeCompare(b.title);
      default:
        return 0;
    }
  });
};

const sortCompletedProjects = (a: IProject, b: IProject) => {
  const aIsCompleted = typeof a.closeTimestamp !== "undefined";
  const bIsCompleted = typeof b.closeTimestamp !== "undefined";

  if (aIsCompleted && !bIsCompleted) {
    return -1;
  } else if (!aIsCompleted && bIsCompleted) {
    return 1;
  } else if (aIsCompleted && bIsCompleted) {
    return b.closeTimestamp - a.closeTimestamp;
  } else {
    return 0;
  }
};

export default useFilteredAndSortedProjects;


