import React from "react";
import { Box, Typography } from "@mui/material";
import { SelectedRolesItems } from "./components/SelectedRolesItems";
import { SelectedSkillsItems } from "./components/SelectedSkillsItems";
import { SelectedCountItems } from "./components/SelectedCountItems";
import {
  IProjectNewRolesBlock,
  IProjectRole,
} from "../../../model/projectData";
import {
  innerBox,
  mainBox,
  outterBox,
  quantityBox,
  roleBox,
  roleItemsBox,
  selectedRolesBox,
  skillCloudBox,
  typograhyMainHeader,
  typographyHeader,
} from "./SelectedRolesStyles";

interface SelectedRolesProps {
  blockStates: IProjectNewRolesBlock[];
  projectRoles?: IProjectRole[]
}

export const SelectedRoles = 
  ({ blockStates, projectRoles }: SelectedRolesProps) => {
       return (
      <>
        <Typography sx={typograhyMainHeader}>Структура команды</Typography>
        <Box sx={mainBox}>
          <Box sx={outterBox}>
            <Box sx={innerBox}>
              <Box sx={roleBox}>
                <Typography sx={typographyHeader}>Роль</Typography>
              </Box>
              <Box sx={skillCloudBox}>
                <Typography sx={typographyHeader}>Облако навыков</Typography>
              </Box>
              <Box sx={quantityBox}>
                <Typography sx={typographyHeader}>Кол.во</Typography>
              </Box>
            </Box>

            <Box sx={roleItemsBox}>
              {blockStates.map((blockState, index) => (
                <Box key={index} sx={selectedRolesBox}>
                  <SelectedRolesItems
                    key={`role-${index}`}
                    blockState={blockState}
                    projectRoles={projectRoles}
                  />
                  <SelectedSkillsItems
                    key={`skill-${index}`}
                    blockState={blockState}
                    projectRoles={projectRoles}
                  />
                  <SelectedCountItems
                    key={`count-${index}`}
                    count={blockState.totalInProject}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

