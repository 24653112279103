import {
  Avatar,
  Box,
  IconButton,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IFriend, IFriends } from "../../model/UserData";
import { tokens } from "../../../../components/theme";
import styles from "./UserFriends.module.css";

export const UserFriends = ({ friends }: { friends: IFriends }) => {
  const [passDown, setPassdown] = useState(true);
  const [friendList, setFriendList] = useState(friends.slice(0, 4));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const nextFriends = () => {
    setPassdown(passDown);
    if (friends.length > 0) {
      setFriendList(friends);
      passUp();
    } else {
      return friends;
    }
  };
  const passUp = () => {
    setPassdown(!passDown);
  };

  const firstFriends = () => {
    setFriendList(friends.slice(0, 4));
    passUp();
  };

  const deleteFriend = () => {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "844px",
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: "Zen Maru Gothic",
            lineHeight: "16px",
            color: colors.grey[200],
          }}
        >
          Друзья онлайн
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: "8px",
            bgcolor: theme.palette.primary.dark,
            mt: "2px",
          }}
        >
          <List
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 130px)",
              gridAutoRows: "142px",
              gap: "12px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {friendList.map((friend: IFriend, index) => (
              <div key={index}>
                {/* <div key={friend.friendListId.friendId}> */}
                <ListItem
                  sx={{
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemAvatar sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        width: "26px",
                        borderRadius: "50%",
                        bgcolor: theme.palette.primary.light,
                        position: "absolute",
                        right: 0,
                        zIndex: 1,
                        textAlign: "center",
                        border: "2px solid #2E3153",
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor: theme.palette.success.light,
                          border: "1px solid #FF4C51",
                        },
                      }}
                      onClick={() => deleteFriend()}
                    >
                      x
                    </Box>

                    <Avatar
                      src={`${friend.avatar}`}
                      sx={{
                        justifySelf: "center",
                        width: 100,
                        height: 100,
                        bgcolor: theme.palette.primary.light,
                      }}
                    />
                  </ListItemAvatar>
                  <Link
                    to={`${friend.friendListId.friendId}`}
                    className={styles.link}
                  >
                    <ListItemText
                      sx={{
                        marginTop: "12px",

                        ".MuiTypography-root": {
                          fontSize: "12px",
                          fontWeight: 300,
                          textAlign: "center",
                          fontFamily: "Nunito",
                          color: colors.grey[200],
                        },
                      }}
                    >
                      {friend.friendFullName}
                    </ListItemText>
                  </Link>
                </ListItem>
              </div>
            ))}
          </List>

          {passDown ? (
            <IconButton
              sx={{
                color: "#fff",
                maxWidth: "100px",
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.0)",
                },
              }}
              onClick={() => nextFriends()}
            >
              <KeyboardArrowDownIcon
                sx={{
                  padding: "2px",
                  height: "36px",
                  width: "36px",
                  background: theme.palette.text.secondary,
                  borderRadius: "50%",
                  color: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.primary.light,
                  },
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                color: "#fff",
                maxWidth: "100px",
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.0)",
                },
              }}
              onClick={() => firstFriends()}
            >
              <KeyboardArrowUpIcon
                sx={{
                  padding: "2px",
                  height: "36px",
                  width: "36px",
                  background: theme.palette.primary.light,
                  borderRadius: "50%",
                  color: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.text.secondary,
                  },
                }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};
