export const RoleCreationBoxStyle = 
    {
        display: "flex",
        flexDirection: "row",
        position: "relative",
        mt: "5px",
        gap: "15px",
        overflowX: "auto",
        width: "840px",
        paddingBottom: "10px",
        "&::-webkit-scrollbar": {
          width: "600px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#2196F3",
          borderRadius: "10px",
        },
      }
