export interface IProjects extends Array<IProject> {}

export interface IProject {
  createrId: number | null;
  id: number;
  type: string;
  scope: string;
  status: string;
  log: string;
  difficulty: number;
  members: Array<IProjectMember>;
  title: string;
  description: string;
  completeCount: number;
  teams: any;
  documents: Array<IProjectDocument>;
  membershipApplication: any;
  peopleCount: number;
  vacancies: Array<IProjectVacancy>;
  isFavorite: boolean;
  userProject: boolean;
  links: Array<IProjectLink>;
  news: Array<IProjectNews>;
  pace: number;
  progress: number;
  startTimestamp: number;
  projectLastUpdate: string;
  closeTimestamp: number;
  userRole: IProjectUserRole;
}

export interface IProjectMember {
  id: number;
  role: IProjectUserRole;
  avatar: string;
  name: string;
  isTeamLeader: boolean;
  isProjectAdmin: boolean;
  isProjectManager: boolean;
}

export interface IProjectUserRole {
  id: number;
  name: string;
  vacancyRole: boolean;
}

export interface IProjectNews {
  id: number;
  infoName: string;
  body: string;
  publishedAt: number;
}
export interface IProjectLink {
  id: number;
  linkName: string;
  description: string;
  publishedAt: number;
}

export interface IProjectDocument {
  id: number;
  fileName: string;
  description: string;
  publishedAt: number;
}

export interface IProjectVacancy {
  id: number;
  totalInProject: number;
  countInProject: number;
  role: IProjectRole;
}

export interface IProjectRole {
  id: number;
  name: string;
  skill: IProjectRolesSkill[];
  vacancyRole: boolean;
}

export interface IProjectRolesSkill {
  id: number;
  name: string;
  instrumentGroup: string;
  subSkills: IProjectRolesSubskills[];
}

export interface IProjectRolesSubskills {
  id: number;
  idSkill: number;
  idSubskill: number;
  subSkillEntity: IProjectSubSKillEntity;
}

export interface IProjectSubSKillEntity {
  id: number;
  name: string;
  instrumentGroup: string | null;
}

export interface IProjectNewRolesBlock {
  selectedRole?: number;
  selectedSkill?: number;
  selectedSubSkills: IProjectRolesSubskills[];
  totalInProject: number;
}

export const sortType = {
  new: "Сначала новые",
  old: "Сначала старые",
  completed: "Сначала завершенные",
  abc: "По алфавиту",
};

export const filterType = {
  Front: "Frontend",
  Back: "Backend",
  Design: "Designer",
  Dev: "DevOps",
  Manager: "Project Manager",
};

export type OmittedProjectDetails = Omit<
  IProject,
  | "id"
  | "type"
  | "scope"
  | "log"
  | "members"
  | "completeCount"
  | "teams"
  | "membershipApplication"
  | "peopleCount"
  | "isFavorite"
  | "closeTimestamp"
  | "documents"
  | "links"
  | "news"
  | "projectLastUpdate"
  | "userRole"
>;
