import React from "react";
import { Box } from "@mui/material";
import CancelIconButton from "@mui/icons-material/Cancel";

interface RoleDeleteBtnProps {
  onBlockReset: (index: number) => void;
  index: number;
}

export const RoleDeleteBtn = ({ onBlockReset, index }: RoleDeleteBtnProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <CancelIconButton
        onClick={() => onBlockReset(index)}
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    </Box>
  );
};
