import React from "react";
import { Box, Typography } from "@mui/material";
import RoleSelectBox from "./RoleSelectBox";
import { IProjectNewRolesBlock, IProjectRole } from "../../model/projectData";
import {
  useHandleRoleChange,
  useSetSelectedSkill,
  useHandleSubSkillConfirm,
  useHandleSubSkillUnconfirm,
  useSetCount,
} from "./RoleCreationHooks/RoleCreationHooks";
import { RoleCreationBoxStyle } from "./RoleCreationStyle";


interface RoleCreationProps {
  onBlockChange: (index: number, block: IProjectNewRolesBlock) => void;
  onBlockReset : (index: number)=>void;
  projectRoles?: IProjectRole[];
  roleBlockStates: IProjectNewRolesBlock[]
  setRoleBlockStates: React.Dispatch<React.SetStateAction<IProjectNewRolesBlock[]>>
  openDropdown: string | null;
  setOpenDropdown: React.Dispatch<React.SetStateAction<string | null>>;
}

export const RoleCreation = ({
  onBlockReset,
  onBlockChange,
  projectRoles,
  roleBlockStates,
  setRoleBlockStates,
  openDropdown,
  setOpenDropdown
 }: RoleCreationProps) => {
  

  const handleRoleChange = useHandleRoleChange(setRoleBlockStates, projectRoles);
  const setSelectedSkill = useSetSelectedSkill(
    setRoleBlockStates,
    projectRoles
  );
  const handleSubSkillConfirm = useHandleSubSkillConfirm(
    setRoleBlockStates,
    onBlockChange
  );
  const handleSubSkillUnconfirm = useHandleSubSkillUnconfirm(
    setRoleBlockStates,
    onBlockChange
  );
  const setCount = useSetCount(setRoleBlockStates, onBlockChange);

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "16px",
          fontWeight: 700,
          ml: "10px",
          mt: "15px",
        }}
      >
        Выбор структуры команды
      </Typography>
      <Box sx={RoleCreationBoxStyle}>
       
        {roleBlockStates.map((roleBlockState, index) => (
          <RoleSelectBox
            key={index}
            blockId = {`role-select-${index}`}
            index={index}
            projectRoles={projectRoles || []}
            role={roleBlockState}
            setSelectedRole={(roleId) => handleRoleChange(index, roleId)}
            setSelectedSkill={(skillId) => setSelectedSkill(index, skillId)}
            onCountChange={(newCount) => setCount(index, newCount)}
            onSubSkillConfirm={(subSkill) =>
              handleSubSkillConfirm(index, subSkill)
            }
            onSubSkillUnconfirm={(subSkill) =>
              handleSubSkillUnconfirm(index, subSkill)
            }
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            onBlockReset = {()=> onBlockReset(index)}
          />
        ))}
      </Box>
    </>
  );
};
