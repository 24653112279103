import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IRole,
  ISkill,
  ISubSkill,
  IQuestion,
} from "../pages/lk/model/UserData";

export const stacksApi = createApi({
  reducerPath: "stacksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://juniorhub.ru",
  }),
  tagTypes: ["Stack"],
  endpoints: (builder) => ({
    getConfirmedSkills: builder.query<any, { id: string; type: string }>({
      query: ({ id, type }) => ({
        url: `/stack/api/v1/stack/user/${id}/type/${type}/confirm`,
      }),
      providesTags: ["Stack"],
    }),

    getSkillsList: builder.query<ISkill[], string>({
      query: (id) => ({
        url: `/stack/api/v1/stack/role/${id}/skill`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["Stack"],
    }),
    getSubSkillsList: builder.query<ISubSkill[], string>({
      query: (id) => ({
        url: `stack/api/v1/stack/role/skill/${id}/subSkill`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["Stack"],
    }),
    getFullStack: builder.query<IRole[], string>({
      query: () => ({
        url: "stack/api/v1/stack/role/",
      }),
      providesTags: ["Stack"],
    }),

    confirmSkill: builder.mutation<ISkill, { idUser: number; idSkill: number }>(
      {
        query: ({ idUser, idSkill }) => ({
          url: `/stack/api/v1/stack/user/${idUser}/skill/${idSkill}/confirm-skill`,
          method: "POST",
          body: idSkill,
        }),
        invalidatesTags: ["Stack"],
      }
    ),
    getQuestions: builder.query<IQuestion[], string>({
      query: (id) => ({
        url: `/stack/api/v1/stack/skill/${id}/questions`,
      }),
      providesTags: ["Stack"],
    }),
  }),
});

export const {
  useGetSkillsListQuery,
  useGetSubSkillsListQuery,
  useGetQuestionsQuery,
  useGetFullStackQuery,
} = stacksApi;
