import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  id: number;
  login: string;
}

export type Auth = {
  token: any;
  user: User;
};

const initialState: Auth = {
  user:
    localStorage && localStorage.user
      ? JSON.parse(localStorage.user)
      : undefined,
  token: undefined,
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
    ) => {
      state.user = user;
      state.token = token;
    },
    setAutorized: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      localStorage.user = JSON.stringify(user);
    },
  },
});

export const { setAutorized, setCredentials } = authSlice.actions;

export const authReducer = authSlice.reducer;
