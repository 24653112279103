import path from "path";
import { MinimalLayout } from "../layout/minimalLayout/MinimalLayout";
import { Login } from "../pages/authorize/login/LoginPage";
import { RegisterPage } from "../pages/authorize/register/RegisterPage";
import { HomePage } from "../pages/homepage/HomePage";
// import { lazyImport } from "../utils/lazyImport";

// const { Login } = lazyImport(
//   () => import("../pages/authorize/login/LoginPage"),
//   "Login"
// );

export const publicRoutes = [
  {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        loader: null,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "*",
        element: <Login />,
      },
    ],
  },
];
