import React, { useState } from "react";
import PrNavBar from "./components/prNavBar/PrNavBar";
import PrSearch from "./components/prSearch/prSearch";
import ProgressBar from "../../components/progressBar/ProgressBar";
import Error from "../../components/error/Error";
import { Typography, Box, Pagination, Grid } from "@mui/material";
import PrItem from "./components/projectItem/ProjectItem";
import PrSearchResults from "./components/prSearchResults/PrSearchResults";
// import { useGetProjectsQuery } from "../../services/projects";
import { useUserId } from "../../features/auth/userContex/UserContex";
import { useGetUserProjectsQuery } from "../../services/projects";
import useFilteredAndSortedProjects from "./components/FilterandSort/useFilteredAndSortedProjects";

export const Projects = () => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedVacancies, setSelectedVacancies] = useState<string[]>([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;
  const { userId, isLoading: isUserLoading } = useUserId();

  const {
    data: projects,
    error,
    isLoading: isProjectLoading,
  } = useGetUserProjectsQuery({ user_id: userId ?? 0 });

  const displayedProjects = useFilteredAndSortedProjects(
    projects || [],
    search,
    sort,
    selectedFilters,
    selectedVacancies,
    false
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(displayedProjects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = displayedProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  if (isUserLoading) {
    return <Box>Loading User Info....</Box>;
  }

  
  if (userId === null) {
    return <Box>User ID is not available.</Box>;
  }

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "28px",
          fontWeight: 700,
        }}
      >
        Проекты
      </Typography>
      <Box sx={{ mt: "10px" }}>
        <PrNavBar onTabChange={() => {}} />
      </Box>
      <PrSearch
        inputSearch={setSearch}
        handleFilterProf={setSelectedFilters}
        sort={setSort}
        searchValue={search}
        onFocus={() => setIsDropdownOpen(false)}
      />
      {isProjectLoading && <ProgressBar />}
      {error && <Error />}
      {!isProjectLoading && !error && (
        <Box sx={{ marginTop: "44px" }}>
          {search !== "" ? (
            <PrSearchResults
              projectList={displayedProjects}
              isFavorite={isFavorite}
            />
          ) : (
            <Grid container spacing={3}>
              {currentProjects.map((listItem) => (
                <Grid item lg={4} md={6} xs={12} key={listItem.id}>
                  <PrItem
                    listItem={listItem}
                    isFavorite={listItem.isFavorite}
                    userId={userId}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            mt: "30px",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="large"
            variant="outlined"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
};

export default Projects;
