import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IFriends, IUserData } from "../pages/lk/model/UserData";
import { RootState } from "../store";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://juniorhub.ru`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authorization");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    authorize: builder.mutation<any, any>({
      query: (credentials) => {
        return {
          url: "/user/api/v1/auth/login",
          method: "POST",
          body: credentials,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          window.localStorage.setItem("authorization", data.accessToken);
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getUserInfo: builder.query<any, any>({
      query: () => ({
        url: `/user/api/v1/users/me`,
      }),
      providesTags: ["User"],
    }),

    getUserData: builder.query<IUserData, string>({
      query: (userid) => ({
        url: `api/user/${userid}/user`,
      }),
      providesTags: ["User"],
    }),
    updateUserData: builder.mutation<IUserData, any>({
      query: (body) => {
        return {
          url: "api/user/update",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    uploadUserAvatar: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "avatar/upload", //другой порт
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    createUser: builder.mutation<IUserData, any>({
      query: (body) => {
        return {
          url: "api/users",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/user/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    createUserSocial: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `api/users/social/add`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUserSocial: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/user/social/del/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    deactUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/user/deact/${userid}`,
          method: "POST",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    addUserContact: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `api/users/contact/add`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUserContact: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/user/contact/del/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    activUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/user/activ/${userid}`,
          method: "POST",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),

    getUserStacks: builder.query<any, string>({
      query: (id) => ({
        url: `${id}/stacks`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"], //другой порт
    }),
    getUserFriends: builder.query<IFriends, string>({
      query: () => ({
        url: `/user/api/v1/friends`,
      }),
      providesTags: ["User"],
    }),
    getUserFriendsList: builder.query<IFriends, string>({
      query: () => ({
        url: "api/friends",
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"],
    }),
    delFriend: builder.query<any, string>({
      query: () => ({
        url: "api/friends/delete",
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"],
    }),
    addFriend: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "api/friends",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getsFriend: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "api/friends/gets",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getUserProjects: builder.query<IUserData, string>({
      query: (userid) => ({
        url: `${userid}/projects`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"], //другой порт
    }),
  }),
});

export const {
  useGetUserFriendsQuery,
  useGetUserProjectsQuery,
  useGetUserStacksQuery,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUploadUserAvatarMutation,
  useActivUserMutation,
  useCreateUserMutation,
  useAddUserContactMutation,
  useCreateUserSocialMutation,
  useDeactUserMutation,
  useGetUserFriendsListQuery,
  useDelUserContactMutation,
  useDelUserMutation,
  useDelUserSocialMutation,
  useAuthorizeMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
} = userApi;
