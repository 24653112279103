import { useCallback, useEffect, useState } from "react";
import {
  BlockStates,
  SetBlockStates,
  OnBlockChange,
  ProjectRoles,
  SubSkill,
} from "./RoleCreationTypes";
import { IProjectRole } from "../../../model/projectData";

export const useBlockStates = (
  initialState: BlockStates,
  onBlockChange: OnBlockChange
): [BlockStates, SetBlockStates] => {
  const [blockStates, setBlockStates] = useState(initialState);

  useEffect(() => {
    blockStates.forEach((blockState, index) => {
      onBlockChange(index, blockState);
    });
  }, [blockStates, onBlockChange]);

  return [blockStates, setBlockStates];
};

export const useHandleRoleChange = (
  setBlockStates: SetBlockStates,
  projectRoles?: IProjectRole[]
): ((index: number, roleId: number) => void) => {
  return useCallback(
    (index, roleId) => {
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        const currentBlockState = updatedBlockStates[index];
        const newRole = projectRoles?.find((role) => role.id === roleId);
        const selectedSkillIsValid = newRole?.skill?.some(
          (oneSkill) => oneSkill.id === currentBlockState.selectedSkill
        );

        updatedBlockStates[index] = {
          ...currentBlockState,
          selectedRole: roleId as number,
          selectedSkill: selectedSkillIsValid
            ? currentBlockState.selectedSkill
            : undefined,
          totalInProject: selectedSkillIsValid
            ? currentBlockState.totalInProject
            : 0,
        };
        return updatedBlockStates;
      });
    },
    [setBlockStates, projectRoles]
  );
};

export const useSetSelectedSkill = (
  setBlockStates: SetBlockStates,
  projectRoles: ProjectRoles
): ((index: number, skillId: number) => void) => {
  return useCallback(
    (index: number, skillId: number) => {
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        const currentBlockState = updatedBlockStates[index];
        const selectedRole = projectRoles?.find(
          (role) => role.id === currentBlockState.selectedRole
        );
        const selectedSkill = selectedRole?.skill.find(
          (oneSkill) => oneSkill.id === skillId
        );

        if (!selectedSkill) {
          return prevStates;
        }

        const subSkills = selectedSkill?.subSkills || [];
        const mandatorySubSkills = subSkills.filter(
          (subSkill) => subSkill.subSkillEntity.instrumentGroup == null
        );

        if (subSkills.length === mandatorySubSkills.length) {
          updatedBlockStates[index] = {
            ...currentBlockState,
            selectedSkill: skillId,
            selectedSubSkills: [...subSkills],
          };
        } else {
          updatedBlockStates[index] = {
            ...currentBlockState,
            selectedSkill: skillId,
            selectedSubSkills: [...mandatorySubSkills],
          };
        }

        return updatedBlockStates;
      });
    },
    [setBlockStates, projectRoles]
  );
};

export const useHandleSubSkillConfirm = (
  setBlockStates: SetBlockStates,
  onBlockChange: OnBlockChange
): ((index: number, subSkill: SubSkill) => void) => {
  return useCallback(
    (index: number, subSkill: SubSkill) => {
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        const currentSubSkills = updatedBlockStates[index].selectedSubSkills;
        const existingSubSkillIndex = currentSubSkills.findIndex(
          (s) => s.subSkillEntity.name === subSkill.subSkillEntity.name
        );

        if (existingSubSkillIndex > -1) {
          currentSubSkills[existingSubSkillIndex] = subSkill;
        } else {
          currentSubSkills.push(subSkill);
        }

        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
          selectedSubSkills: currentSubSkills,
        };
        onBlockChange(index, updatedBlockStates[index]);
        return updatedBlockStates;
      });
    },
    [setBlockStates, onBlockChange]
  );
};

export const useHandleSubSkillUnconfirm = (
  setBlockStates: SetBlockStates,
  onBlockChange: OnBlockChange
) => {
  return useCallback(
    (index: number, subSkill: SubSkill) => {
      if (subSkill.subSkillEntity.name == null) {
        return;
      }

      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        const currentSubSkills = updatedBlockStates[index].selectedSubSkills;
        const updatedSubSkills = currentSubSkills.filter(
          (s) => s.subSkillEntity.name !== subSkill.subSkillEntity.name
        );

        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
          selectedSubSkills: updatedSubSkills,
        };
        onBlockChange(index, updatedBlockStates[index]);
        return updatedBlockStates;
      });
    },
    [setBlockStates, onBlockChange]
  );
};

export const useSetCount = (
  setBlockStates: SetBlockStates,
  onBlockChange: OnBlockChange
) => {
  return useCallback(
    (index: number, newCount: number) => {
      newCount = Math.min(newCount, 9);
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
          totalInProject: newCount,
        };
        onBlockChange(index, updatedBlockStates[index]);
        return updatedBlockStates;
      });
    },
    [setBlockStates, onBlockChange]
  );
};
