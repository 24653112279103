import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PrNavBar from "../components/prNavBar/PrNavBar";
import { DifficultyStars } from "./components/DifficultyStars";
import { useGetProjectByIdQuery } from "../../../services/projects";
import { ProjectInfoDesk } from "./components/PrInfoDesk/ProjectInfoDesk";
import { ProjectWorkingPanel } from "./components/PrWorkingPanel/ProjectWorkingPanel";
import {
  difficultyTextStyle,
  projectInfoBoxStyle,
  projectSquadTextSyle,
  titleBoxStyle,
  titleStyle,
} from "./styles/ProjectOwnerDetailsStyles";
import { ProjectImportant } from "./components/PrWorkingPanel/components/ProjectImportant";
import { useUserId } from "../../../features/auth/userContex/UserContex";

export const ProjectOwnerDetails = () => {
  const { id } = useParams();
  const { userId, isLoading: isUserLoading } = useUserId();
  const { data: project } = useGetProjectByIdQuery({
    projectId: Number(id),
    userId: userId ?? 0,
  });
  if (isUserLoading) {
    return <Box>Loading User Info....</Box>;
  }
  return (
    <Box className="Container">
      <Box>
        <Typography sx={titleStyle}>{project?.title}</Typography>
      </Box>
      <Box sx={titleBoxStyle}>
        <Box>
          <PrNavBar onTabChange={() => {}} />
        </Box>
        <Box
          className="Difficulty"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={difficultyTextStyle}>Сложность</Typography>
          <DifficultyStars difficulty={project?.difficulty} />
        </Box>
      </Box>
      <Box sx={projectInfoBoxStyle}>
        <Box>
          <ProjectInfoDesk project={project} />
          <Typography sx={projectSquadTextSyle}>
            Текущий состав проекта
          </Typography>
        </Box>
        <Box sx={{ ml: "35px" }}>
          <ProjectImportant projectNews={project?.news} />
          <ProjectWorkingPanel
            projectDocuments={project?.documents}
            projectLinks={project?.links}
          />
        </Box>
      </Box>
    </Box>
  );
};
