import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  documentsOutterBoxStyle,
  documentsTypographyStyle,
} from "./styles/WorkingPanelStyles";
import { IProjectDocument } from "../../../../model/projectData";
import { DocumentsItems } from "./DocumentsItems";
import { ViewAllItemsButton } from "./ViewAllItemsButton";

interface ProjectDocumentsProps {
  projectDocuments?: IProjectDocument[];
}

export const ProjectDocuments = ({
  projectDocuments,
}: ProjectDocumentsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Typography sx={documentsTypographyStyle}>Документы</Typography>
      <Box sx={documentsOutterBoxStyle(isExpanded)}>
        {projectDocuments && projectDocuments.length > 0 ? (
          projectDocuments
            .slice(0, isExpanded ? projectDocuments.length : 2)
            .map((document: IProjectDocument) => (
              <DocumentsItems key={document.id} props={document} />
            ))
        ) : (
          <Typography sx={{ textAlign: "center", mt: "50px" }}>
            Документы отсутствуют, добавьте новый документ
          </Typography>
        )}
        <ViewAllItemsButton isExpanded={isExpanded} onClick={handleClick} />
      </Box>
    </>
  );
};
