import { Box, Typography, Grid, Pagination } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IProject } from "../model/projectData";
import { projectsApi } from "../../../services/projects";
import PrNavBar from "../components/prNavBar/PrNavBar";
import PrItem from "../components/projectItem/ProjectItem";
import PrSearch from "../components/prSearch/prSearch";
import useFilteredAndSortedProjects from "../components/FilterandSort/useFilteredAndSortedProjects";
import { useUserId } from "../../../features/auth/userContex/UserContex";

export default function MyProjects() {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [originalUserProjects, setOriginalUserProjects] = useState<IProject[]>(
    []
  );
  const { userId, isLoading: isUserLoading } = useUserId();

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  const {
    data: projects,
    error,
    isLoading,
  } = projectsApi.useGetUserProjectsQuery({ user_id: userId ?? 0 });

  const filteredProjects = projects?.filter((project: IProject) => project.userProject) || [];

  const userProjects = useFilteredAndSortedProjects(
    filteredProjects,
    search,
    sort,
    selectedFilters,
    [],
    false
  );

  useEffect(() => {
    setOriginalUserProjects(userProjects);
  }, [userProjects]);

  const handleLike = (project: IProject) => {};

  const handleTabChange = (tab: string) => {};

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(userProjects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = userProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  if (isUserLoading) {
    return <Box>Loading User Info....</Box>;
  }

  if (userId === null) {
    return <Box>User ID is not available.</Box>;
  }

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "28px",
          fontWeight: 700,
        }}
      >
        Проекты
      </Typography>
      <Box
        sx={{
          mt: "10px",
        }}
      >
        <PrNavBar onTabChange={handleTabChange} />
      </Box>
      <PrSearch
        inputSearch={setSearch}
        handleFilterProf={setSelectedFilters}
        sort={setSort}
        searchValue={search}
      />

      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "28px",
          fontWeight: 700,
          mt: "10px",
        }}
      >
        Мои Проекты
      </Typography>
      {userProjects.length === 0 ? (
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: 700,
            mt: "150px",
            textAlign: "center",
          }}
        >
          Добавьтесь в проект или создайте новый, чтобы он отобразился в этой
          вкладке.
        </Typography>
      ) : (
        <Grid sx={{ mt: "20px" }} container spacing={3}>
          {currentProjects.map((listItem: IProject) => {
            return (
              <Grid item lg={4} md={6} xs={12} key={listItem.id}>
                <PrItem
                  listItem={listItem}
                  isFavorite={listItem.isFavorite}
                  userId={userId}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            mt: "30px",
            justifyContent: "center",
            alignSelf: "flex-end",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="large"
            variant="outlined"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
}
