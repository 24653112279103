import React, { ChangeEvent } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { projectNameBoxStyle, titleStyle } from "./ProjectHeaderStyles";

interface ProjectNameProps {
  prNameValue?: string;
  prNameOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export const ProjectName = ({
  prNameValue,
  prNameOnChange,
}: ProjectNameProps) => {
  return (
    <Box>
      <Typography sx={titleStyle}>Название проекта</Typography>
      <Box sx={projectNameBoxStyle}>
        <TextField
          value={prNameValue}
          onChange={prNameOnChange}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            maxLength: 30,
          }}
        />
      </Box>
    </Box>
  );
};
