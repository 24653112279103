import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthorizeMutation } from "../../../../services/user";
import { enterBtnStyle, textFildStyle } from "../../authoriseStyle";

export const LoginForm = () => {
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const navigate = useNavigate();
  const [updateUser] = useAuthorizeMutation();
  const handleSubmit = async () => {
    await updateUser({
      username: loginData.username,
      password: loginData.password,
    });
    navigate("/profile");
  };

  return (
    <Box maxWidth="368px" m="1.5rem auto" sx={{}}>
      <Box
        display="grid"
        gridTemplateColumns="1fr"
        rowGap="0.75rem"
        gridAutoRows="minmax(3rem, auto)"
      >
        <Box
          sx={{
            mt: "24px",
            height: "20px",
            fontSize: "14px",
          }}
        >
          Имя пользователя или E-mail
        </Box>
        <OutlinedInput
          name="username"
          value={loginData.username}
          label="Имя пользователя или E-mail"
          style={textFildStyle}
          onChange={(e: any) => handleChange(e)}
          sx={{
            fontSize: "14px",
          }}
        />
        <Box
          sx={{
            mt: "24px",
            height: "20px",
            fontSize: "14px",
          }}
        >
          Пароль
        </Box>
        <OutlinedInput
          name="password"
          type="password"
          value={loginData.password}
          style={textFildStyle}
          label="Пароль"
          onChange={(e: any) => handleChange(e)}
          sx={{
            borderRadius: "4px",
            background: "trasnparent",
            fontFamily: "Nunito",
            fontSize: "14px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
              },
            }}
            control={
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#eaeaea",
                  },
                  "&.MuiCheckbox-colorPrimary": {
                    color: "#eaeaea",
                  },
                }}
              />
            }
            label="Запомни меня"
          />
          <Link
            to="/"
            style={{
              color: "#00A1E7",
            }}
          >
            Забыли пароль?
          </Link>
        </Box>
        <Button
          variant="contained"
          type="submit"
          style={enterBtnStyle}
          sx={{
            textTransform: "none",
          }}
          fullWidth={true}
          onClick={() => handleSubmit()}
        >
          Войти
        </Button>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          Новый пользователь?{" "}
          <Link
            to="/register"
            style={{
              color: "#00A1E7",
            }}
          >
            Регистрация
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
