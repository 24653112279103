import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { IProjectRolesSkill, IProjectVacancy } from "../../model/projectData";

interface VacancyProps {
  vacancy: IProjectVacancy;
}

const style = {
  minWidth: "max-content",
  height: 27,
  backgroundColor: "#D6F3FF",
  borderRadius: 4,
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: 400,
  textTransform: "capitalize",
  color: "#101335",
  mr: 0.6,
  mb: 1,
};

export default function Vacancy({ vacancy }: VacancyProps) {
  const description = `${vacancy?.role.name}: ${vacancy?.role.skill?.map(
      (oneSkill: IProjectRolesSkill) =>
        `${oneSkill.name}: ${oneSkill.subSkills?.map((subSkill) => subSkill.subSkillEntity.name)
          .join(", ")}`
    )
    .join(", ")}`;

  return (
    <Tooltip title={description} arrow>
      <Chip key={vacancy.id} label={vacancy.role.name} sx={style} />
    </Tooltip>
  );
}
