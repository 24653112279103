import React, { ChangeEvent } from "react";
import {
  IProjectRole,
  IProjectNewRolesBlock,
  OmittedProjectDetails,
  IProject,
  IProjectVacancy,
} from "../../model/projectData";

export const handleInputChange =
  (setState: React.Dispatch<React.SetStateAction<string>>) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

export const createInitialRoles = (
  projectData?: IProject,
  initialNewRoleState?: IProjectNewRolesBlock
) => {
  if (projectData) {
    const initialRoles =
      projectData.vacancies.length > 0
        ? projectData.vacancies.map((vacancy: IProjectVacancy) => ({
            selectedRole: vacancy.role.id,
            selectedSkill: vacancy.role.skill[0].id,
            selectedSubSkills: vacancy.role.skill.flatMap((oneSkill) =>
              oneSkill.subSkills.map((subSkill) => subSkill)
            ),
            totalInProject: vacancy.totalInProject ,
          }))
        : Array(3).fill({ ...initialNewRoleState });
    while (initialRoles.length < 3) {
      initialRoles.push({ ...initialNewRoleState });
    }
    return initialRoles;
  } else {
    return Array(3).fill({ ...initialNewRoleState });
  }
};

export const createVacancies = (
  projectNewRoles: IProjectNewRolesBlock[],
  projectRoles: IProjectRole[] | undefined
) => {
  return projectNewRoles
    .filter((projectNewRole) => projectNewRole.selectedRole)
    .map((projectNewRole) => {
      const roleWithSkills = projectNewRole.selectedRole
        ? projectRoles?.find((role) => role.id === projectNewRole.selectedRole)
        : null;

      const roleTitle = roleWithSkills ? roleWithSkills.name : "";

      const selectedSkills = Array.isArray(projectNewRole.selectedSkill)
        ? projectNewRole.selectedSkill
        : projectNewRole.selectedSkill
        ? [projectNewRole.selectedSkill]
        : [];

      const selectedSubSkills = projectNewRole.selectedSubSkills || [];

      const skillsWithTitles = selectedSkills.map((selectedSkillId) => {
        const foundSkill = roleWithSkills?.skill.find(
          (oneSkill) => oneSkill.id === selectedSkillId
        );

        const subSkillsForSkill = foundSkill
          ? foundSkill.subSkills.filter((subSkill) =>
              selectedSubSkills.some(
                (selectedSubSkill) => selectedSubSkill.id === subSkill.id
              )
            )
          : [];

        return {
          id: foundSkill?.id,
          name: foundSkill?.name ?? "",
          instrumentGroup: foundSkill?.instrumentGroup,
          subSkills: subSkillsForSkill.map((subSkill) => ({
            id: subSkill.id,
            idSkill: selectedSkillId,
            idSubSkill: subSkill.subSkillEntity.id,
            subSkillEntity: {
              id: subSkill.subSkillEntity.id,
              name: subSkill.subSkillEntity.name,
              instrumentGroup: subSkill.subSkillEntity.instrumentGroup ?? null,
            }
            
          })),
        };
      });

      return {
        totalInProject: projectNewRole.totalInProject || 0,
        countInProject: 0,
        role: {
          id: roleWithSkills?.id,
          name: roleTitle,
          skill: skillsWithTitles,
        },
      };
    });
};

export const createNewProject = (
  createrId: number | null,
  projectName: string,
  projectDisc: string,
  projectWHCount: number,
  projectStatus: string,
  currentTimestamp: number,
  vacancies: any
): OmittedProjectDetails => {
  return {
    createrId: createrId,
    title: projectName,
    description: projectDisc,
    pace: projectWHCount,
    userProject: true,
    difficulty: 0,
    startTimestamp: currentTimestamp,
    status: projectStatus,
    progress: 0,
    vacancies: vacancies,
  };
};

export const handleError = (error: unknown) => {
  if (error instanceof Error) {
    alert(`Произошла ошибка: ${error.message}`);
  } else {
    alert("Произошла неизвестная ошибка");
  }
};
