import React, { useEffect, useState } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { modalWindowStyles } from "./ModalStyles";

interface ProjectCreationModalProps {
  warnings: string[];
  setWarnings: React.Dispatch<React.SetStateAction<string[]>>;
}
const ProjectCreationModal = ({
  warnings,
  setWarnings,
}: ProjectCreationModalProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (warnings.length > 0) {
      setOpen(true);
    }
  }, [warnings]);

  const handleClose = () => {
    setOpen(false);
    setWarnings([]);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalWindowStyles}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Предупреждения:</Typography>
        </Box>
        {warnings.map((warning: any, index: number) => (
          <Typography key={index}>{warning}</Typography>
        ))}
      </Box>
    </Modal>
  );
};

export default ProjectCreationModal;
