export const modalWindowStyles = {
    width: "584px",
    minHeight: "279px",
    borderRadius: "40px",
    backgroundColor: "#D6F3FF",
    color: "black",
    fontFamily: "Nunito",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 4,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }