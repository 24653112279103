import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_PORT}`,
  prepareHeaders: (headers, { getState }: any) => {
    const { accessToken } = getState().auth;
    headers.set("authorization", accessToken);
    return headers;
  },
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (build) => ({
    sendUserData: build.mutation<any, any>({
      query: (credentials) => ({
        url: `login`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});
export const { useSendUserDataMutation } = authApi;
