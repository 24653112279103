import React, { ChangeEvent } from "react";
import { Box } from "@mui/material";
import { ProjectName } from "./components/ProjectName";
import { ProjectDiscription } from "./components/ProjectDiscription";
import { ProjectContactInfo } from "./components/ProjectContactInfo";
import { PrWorkingHours } from "./components/ProjectWorkingHours";
import { IUserData } from "../../../../lk/model/UserData";

interface ProjectCreateInfoProps {
  name: string;
  disc: string;
  pace: number;
  userData?: IUserData;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDiscChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onHoursChange: (newCount: number) => void;
}

export const ProjectCreateInfo = ({
  name,
  disc,
  pace,
  userData,
  onNameChange,
  onDiscChange,
  onHoursChange,
}: ProjectCreateInfoProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "755px",
      }}
    >
      <ProjectName prNameValue={name} prNameOnChange={onNameChange} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "15px",
        }}
      >
        <Box>
          <ProjectDiscription
            prDiscValue={disc}
            prDiscOnChange={onDiscChange}
          />
        </Box>
        <Box
          sx={{
            diplay: "flex",
            flexDirection: "column",
          }}
        >
          <ProjectContactInfo mail={userData?.email} phone={userData?.phone} />
          <PrWorkingHours
            hoursCount={pace}
            hoursOnCountChange={onHoursChange}
          />
        </Box>
      </Box>
    </Box>
  );
};
