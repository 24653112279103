import React, { useRef } from "react";
import { useHandleOutsideClick } from "../../helpers/useHandleOutsideClick";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Theme } from "../../../../../../node_modules/@mui/material/";

const boxStyles = (theme: Theme) => ({
  position: "relative",
  minWidth: "270px",
  height: "300px",
  border: "solid 1px",
  borderColor: "#82D9FF",
  borderRadius: "8px",
  p: "20px",
  background: theme.palette.background.paper,
  overflowX: "hidden",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#2196F3",
    borderRadius: "10px",
  },
});

export const innerBoxStyles = {
  width: "225px",
  minHeight: "36px",
  ml: "auto",
  mr: "auto",
  bgcolor: "#D6F3FF",
  color: "black",
  borderRadius: "4px",
  display: "flex",
  px: "7px",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 500,
};

export const skillBoxStyles = {
  mt: "10px",
  width: "225px",
  height: "26px",
  ml: "auto",
  mr: "auto",
  bgcolor: "#82D9FF",
  borderRadius: "4px",
  position: "relative",
  display: "flex",
  px: "7px",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: 400,
  color: "black",
};

const skillMenuStyles = {
  position: "absolute",
  top: "100%",
  left: 0,
  minWidth: "100%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  zIndex: 1,
  fontFamily: "Nunito",
  color: "black",
};


interface SkillMenuBoxProps {
  children: React.ReactNode;
  onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  open: boolean;
  onOutsideClick: () => void;
}

export const OuterBox = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useTheme();
  return <Box sx={boxStyles(theme)}>{children}</Box>;
};

export const SkillMenuBox = ({
  children,
  onMouseDown,
  open,
  onOutsideClick,
}: SkillMenuBoxProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick(open, onOutsideClick, menuRef);
  return (
    <Box sx={skillMenuStyles} onMouseDown={onMouseDown}>
      {children}
    </Box>
  );
};
