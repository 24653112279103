import React from "react";
import { Box, Link, Stack, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import LikeBtn from "./LikeBtn";
import VacanciesList from "./VacanciesList";
import { IProject, IProjectVacancy } from "../../model/projectData";
import DelBtn from "./DelBtn";
import StatusIndicator from "./StatusIndicator";
import { EditBtn } from "./EditBtn";

interface CardProps {
  listItem: IProject;
  vacancies: IProjectVacancy[];
  isFavorite: boolean;
  userId: number;
}

export default function Card({
  listItem,
  vacancies,
  isFavorite,
  userId,
}: CardProps) {
  const { title, description, difficulty, status } = listItem;
  const theme = useTheme();

  return (
    <Container
      sx={{
        width: "100%",
        maxWidth: "368px",
        height: "464px",
        background: theme.palette.background.paper,
        borderRadius: "8px",
        pt: "25px",
        pb: "17px",
        px: "25px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        <Stack maxWidth={"316px"} height={"51px"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                flexGrow: 1,
                maxWidth: "294px",
                fontFamily: "Zen Maru Gothic",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              {title}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: "25px",
                right: "25px",
              }}
            >
              <LikeBtn project={listItem} isFavorite={isFavorite} />
              {listItem.createrId === userId && (
                <>
                  <EditBtn projectId={listItem.id} />
                  <DelBtn project={listItem} />
                </>
              )}
            </Box>
          </Stack>
          <Box
            sx={{
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            Сложность {difficulty}/10
          </Box>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "241px",
            mt: "25px",
          }}
        >
          <Box
            sx={{
              display: "inline",
              fontFamily: "Zen Maru Gothic",
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            Описание
          </Box>
          <Box
            sx={{
              display: "block",
              overflow: "hidden",
              whiteSpace: "normal",
              textOverflow: "ellipsis",
              width: "318px",
              height: "197px",
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {description}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              mt: "5px",
              color: "#00A1E7",
            }}
          >
            {userId === listItem.createrId ? (
              <Link href={`/projects/${listItem.id}`}>Подробнее</Link>
            ) : (
              <Link href="#">Подробнее</Link>
            )}
          </Box>
        </Box>
        <Box>
          <VacanciesList vacancies={vacancies} />
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <StatusIndicator status={status} />
        </Box>
      </Box>
    </Container>
  );
}
