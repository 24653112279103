import { Avatar, Box } from "@mui/material";
import { IProjectDocument } from "../../../../model/projectData";
import {
  DocumentsAndLinksDiscriptionStyle,
  DocumentsAndLinksInnerBoxStyle,
  DocumentsAndLinksTimeBoxStyle,
  DocumentsAndLinksTitleStyle,
} from "./styles/DocumentsAndLinksStyles";
import { convertTimestampToTime } from "./TimeConvertHelper";

interface DocumentsItemsProps {
  props: IProjectDocument;
}

export const DocumentsItems = ({
  props,
}: DocumentsItemsProps) => {
  const propTime = props.publishedAt;
  const time = convertTimestampToTime(propTime);
  const userAvatar = "https://picsum.photos/200/300";
  return (
    <Box  sx={DocumentsAndLinksInnerBoxStyle}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignSelf: "center" }}>
          <Avatar
            src={userAvatar}
            alt={props.fileName}
            sx={{width: "38px",height: "38px"}}
          />
        </Box>
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <Box sx={DocumentsAndLinksTitleStyle}>{props.fileName}</Box>
          <Box sx={DocumentsAndLinksDiscriptionStyle}>{props.description}</Box>
        </Box>
      </Box>
      <Box sx={DocumentsAndLinksTimeBoxStyle}>{time}</Box>
    </Box>
  );
};
