import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { AppRoutes } from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import { ColorModeContext, useMode } from "./components/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import "./fonts.css";
import { UserProvider } from "./features/auth/userContex/UserContex";

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<AppRoutes />} />)
);

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <UserProvider>
          <RouterProvider router={router} />
          </UserProvider>
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
