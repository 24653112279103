import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IProject, IProjectRole } from "../pages/projects/model/projectData";

enum TagTypes {
  Projects = "Projects",
  ProjectRoles = "ProjectRoles",
  ProjectRoleById = "ProjectRoleById",
}

// const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({
  baseUrl: `https://juniorhub.ru`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authorization");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    TagTypes.Projects,
    TagTypes.ProjectRoles,
    TagTypes.ProjectRoleById,
  ],
  endpoints: (builder) => ({
    getUserProjects: builder.query<IProject[], { user_id: number }>({
      query: (user_id) => ({
        url: `project/api/v1/projects/all`,
        params: user_id,
        limit: 20,
      }),
      providesTags: [TagTypes.Projects],
    }),
    getProjectById: builder.query<
      IProject,
      { projectId: number; userId: number }
    >({
      query: ({ projectId, userId }) => ({
        url: `/project/api/v1/projects/${projectId}`,
        params: { user_id: userId },
      }),
      providesTags: [TagTypes.Projects],
    }),

    addToFavorite: builder.mutation({
      query({ id, body }) {
        return {
          url: `/project/api/v1/projects/${id}/favorites`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [TagTypes.Projects],
    }),
    deleteMyProject: builder.mutation({
      query({ id }) {
        return {
          url: `/project/api/v1/projects/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [TagTypes.Projects],
    }),
    getAllProjectsRole: builder.query<IProjectRole[], void>({
      query: () => ({
        url: `/stack/api/v1/stack/role/`,
      }),
      providesTags: [TagTypes.ProjectRoles],
    }),
    getProjectRoleById: builder.query<IProjectRole, number>({
      query: (id) => ({
        url: `/stack/api/v1/stack/role/${id}`,
        
      }),
      providesTags: [TagTypes.ProjectRoleById],
    }),
    createOrUpdateProject: builder.mutation<
      IProject,
      { project: Partial<IProject>; id?: number }
    >({
      query: ({ project, id }) => ({
        url: id ? `/project/api/v1/projects/${id}` : "/project/api/v1/projects/",
        method: id ? "PUT" : "POST",
        body: project,
      }),
      invalidatesTags: [TagTypes.Projects],
    }),
  }),
});

export const {
  useGetUserProjectsQuery,
  useGetProjectByIdQuery,
  useAddToFavoriteMutation,
  useDeleteMyProjectMutation,
  useGetAllProjectsRoleQuery,
  useGetProjectRoleByIdQuery,
  useCreateOrUpdateProjectMutation,
} = projectsApi;
