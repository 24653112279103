import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { IProjectNewRolesBlock } from "../model/projectData";
import {
  createVacancies,
  createNewProject,
  handleInputChange,
  handleError,
  createInitialRoles,
} from "./helpers/projectHelpers";
import { ProjectManager } from "./component/PrManager/ProjectManager";
import { ProjectCreateInfo } from "./component/PrCreateHeader/ProjectCreateInfo";
import { RoleCreation } from "./component/RoleCreation";
import { SelectedRoles } from "./component/SelectedRoles/SelectedRoles";
import { useGetUserDataQuery } from "../../../services/user";
import {
  useCreateOrUpdateProjectMutation,
  useGetAllProjectsRoleQuery,
  useGetProjectByIdQuery,
} from "../../../services/projects";
import { useUserId } from "../../../features/auth/userContex/UserContex";
import ProjectCreationModal from "./component/ProjectCreateModal/ProjectCreationModal";
import {
  addNewBlockIfNeeded,
  handleBlockChange,
  handleBlockReset,
  validateProjectData,
} from "./helpers/PrCreatehelpers";

export const ProjectCreate = () => {
  const navigate = useNavigate();
  const { userId, isLoading: isUserLoading } = useUserId();
  const createrId = userId;
  const parsedUserId = String(userId);
  const { id } = useParams();
  const parsedProjectId = Number(id);
  const { data: projectData } = useGetProjectByIdQuery({projectId: parsedProjectId, userId: userId ?? 0});
  const { data: projectRoles } = useGetAllProjectsRoleQuery();
  const { data: userData } = useGetUserDataQuery(parsedUserId);
  const [createOrUpdateProjectMutation] = useCreateOrUpdateProjectMutation();

  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const [projectName, setProjectName] = useState("");
  const [projectDisc, setProjectDisc] = useState("");
  const [projectWHCount, setProjectWHCount] = useState(0);
  const [projectStatus, setProjectStatus] = useState("Not Posted");
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [warnings, setWarnings] = useState<string[]>([]);

  const handleProjectNameChange = handleInputChange(setProjectName);
  const handleProjectDiscChange = handleInputChange(setProjectDisc);

  const handleProjectHoursChange = (newHoursCount: number) => {
    if (newHoursCount >= 0 && newHoursCount <= 168) {
      setProjectWHCount(newHoursCount);
    }
  };
  const handleProjectStatusChange = (status: string) => {
    setProjectStatus(status);
  };

  const initialNewRoleState: IProjectNewRolesBlock = useMemo(
    () => ({
      selectedRole: undefined,
      selectedSkill: undefined,
      selectedSubSkills: [],
      totalInProject: 0,
    }),
    []
  );

  const [newRoleBlockStates, setNewRoleBlockStates] = useState<
    IProjectNewRolesBlock[]
  >([{ ...initialNewRoleState }]);

  const handleBlockChangeWrapper = (
    index: number,
    newBlock: IProjectNewRolesBlock
  ) => {
    setNewRoleBlockStates((prevStates) =>
      handleBlockChange(prevStates, index, newBlock)
    );
  };

  const handleBlockResetWrapper = (index: number) => {
    setNewRoleBlockStates((prevStates) =>
      handleBlockReset(prevStates, index, initialNewRoleState)
    );
  };

  useEffect(() => {
    if (projectData) {
      setProjectName(projectData.title);
      setProjectDisc(projectData.description);
      setProjectWHCount(projectData.pace);
      setProjectStatus(projectData.status);
    }
    setNewRoleBlockStates(createInitialRoles(projectData, initialNewRoleState));
  }, [projectData, setNewRoleBlockStates, initialNewRoleState]);

  useEffect(() => {
    addNewBlockIfNeeded(
      newRoleBlockStates,
      setNewRoleBlockStates,
      initialNewRoleState
    );
  }, [newRoleBlockStates, initialNewRoleState]);

  const handleNewProjectCreate = async () => {
    const newWarnings = validateProjectData(
      projectName,
      projectDisc,
      projectWHCount,
      newRoleBlockStates.map((block) => block.selectedRole)
    );
    if (newWarnings.length > 0) {
      setWarnings(newWarnings);
      return;
    }
    try {
      const vacancies = createVacancies(newRoleBlockStates, projectRoles);
      const newProject = createNewProject(
        createrId,
        projectName,
        projectDisc,
        projectWHCount,
        projectStatus,
        currentTimestamp,
        vacancies
      );

      await createOrUpdateProjectMutation({
        project: newProject,
        id: parsedProjectId,
      });
      navigate("/projects/myprojects");
    } catch (error) {
      handleError(error);
    }
  };

  if (isUserLoading) {
    return <Box>Loading User Info....</Box>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <ProjectManager
          handleCreateProject={handleNewProjectCreate}
          currentName={projectName}
          projectDataStatus={projectData?.status}
          onStatusChange={handleProjectStatusChange}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
        />
      </Box>
      <Box
        sx={{
          ml: "30px",
        }}
      >
        <>
          <ProjectCreateInfo
            name={projectName}
            disc={projectDisc}
            pace={projectWHCount}
            userData={userData}
            onNameChange={handleProjectNameChange}
            onDiscChange={handleProjectDiscChange}
            onHoursChange={handleProjectHoursChange}
          />
          <RoleCreation
            onBlockReset={handleBlockResetWrapper}
            onBlockChange={handleBlockChangeWrapper}
            projectRoles={projectRoles}
            roleBlockStates={newRoleBlockStates}
            setRoleBlockStates={setNewRoleBlockStates}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
          <SelectedRoles
            blockStates={newRoleBlockStates}
            projectRoles={projectRoles}
          />
        </>
      </Box>
      <ProjectCreationModal warnings={warnings} setWarnings={setWarnings} />
    </Box>
  );
};
