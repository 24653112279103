import { Box, Typography } from "@mui/material";

import { LoginForm } from "./components/LoginForm";

export const Login = () => {
  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "rgba(6, 9, 43, 0.6)",
          width: "370px",
          borderRadius: "40px",
          p: "24px",
        }}
      >
        <Typography
          variant="h1"
          fontSize="24px"
          sx={{ textAlign: "center", fontFamily: "Nunito", fontWeight: 700 }}
        >
          Войти в аккаунт
        </Typography>
        <LoginForm />
      </Box>
    </Box>
  );
};
