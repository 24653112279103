import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

interface EditBtnProps {
  projectId: number;
}
export const EditBtn = ({ projectId }: EditBtnProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/projects/edit/${projectId}`);
  };
  return (
    <IconButton
      sx={{ pt: "8px", width: "22px", height: "22px" }}
      onClick={handleClick}
    >
      <CreateIcon />
    </IconButton>
  );
};
